import React, { Component } from 'react';
import { Link } from 'react-scroll';
import Reveal from 'react-reveal/Reveal'
class Footer extends Component {
    render() {
        let FooterData = this.props.FooterData;
        return (
            <footer className="new_footer_area bg_color">
                <div className="new_footer_top">
                    <div className="container">
                        <div className="row">
                            {
                                FooterData.CompanyWidget.map(widget => {
                                    return (
                                        <Reveal effect="fadeInLeft" duration={500} key={1}>
                                            <div className="col-lg-3 col-md-6" >
                                                <div className="f_widget dark_widget company_widget" data-wow-delay="0.2s">
                                                    <a href="/" className="f-logo"><img src={require("../../img/logo-f.png")} alt="" /></a>
                                                    <p className="dot-number">DOT Number - 2165795 </p>
                                                    <p className="dock-number">Docket Number- MC753531</p>
                                                </div>
                                            </div>
                                        </Reveal>
                                    )
                                })
                            }
                            {
                                FooterData.AboutWidget.map(widget => {
                                    return (
                                        <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s">
                                                    <h3 className="f-title f_600 t_color f_size_18">{widget.title}</h3>
                                                    <ul className="list-unstyled f_list">
                                                        {
                                                            widget.menuItems.map(item => {
                                                                return (
                                                                    <li><Link activeClass="active" to={item.url} spy={true} smooth={true} offset={-90} duration={1000}>{item.text}</Link></li>
                                                                )
                                                            })
                                                        }

                                                    </ul>
                                                </div>
                                            </div>
                                        </Reveal>
                                    )
                                })
                            }

                            {
                                FooterData.AboutWidget.map(widget => {
                                    return (
                                        <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="f_widget about-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s">
                                                    <h3 className="f-title f_600 t_color f_size_18">Help</h3>
                                                    <ul className="list-unstyled f_list">
                                                        <li><a href="/Terms" target="_blank">Terms & Conditions</a></li>
                                                        <li><a href="/Privacy" target="_blank">Privacy Policy</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </Reveal>
                                    )
                                })
                            }
                            {
                                FooterData.SocialLinks.map(widget => {
                                    return (
                                        <Reveal effect="fadeInLeft" duration={500} key={widget.id}>
                                            <div className="col-lg-3 col-md-6">
                                                <div className="f_widget social-widget pl_70 wow fadeInLeft" data-wow-delay="0.4s">
                                                    <h3 className="f-title f_600 t_color f_size_18">{widget.title}</h3>
                                                    <div className="f_social_icon">
                                                        {
                                                            widget.menuItems.map(item => {
                                                                return (
                                                                    <Link to="#" key={item.id}><i className={item.icon}></i></Link>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </Reveal>
                                    )
                                })
                            }
                        </div>

                    </div>



                    <div className="footer_bg">
                        <div className="footer_bg_one"></div>
                        <div className="footer_bg_two"></div>
                    </div>
                </div>
                <div className="footer_bottom">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-sm-7">
                                <p className="mb-0 f_400">{FooterData.copywrite}</p>
                            </div>
                            <div className="col-lg-6 col-sm-5 text-right">
                                <p>Made with  <i className="icon_heart"></i> by <a href="https://www.waveinfotech.biz/" target="_blank" rel="noopener noreferrer">Wave Infotech </a></p>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
        )
    }
}
export default Footer;
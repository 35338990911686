import React, { Component } from 'react';
import Plx from "react-plx";
import Reveal from "react-reveal"

class AppFeaturesTwo extends Component {
  render() {
    const exampleParallaxData = [
      {
        start: 700,
        duration: 100,
        properties: [
          {
            startValue: -100,
            endValue: -20,
            property: "translateY"
          },
          {
            startValue: 1,
            endValue: 20,
            property: "translateX"
          },
          {
            startValue: 0,
            endValue: 20,
            property: "translate"
          },
          {
            startValue: 0,
            endValue: 2,
            property: "translateY"
          }
        ]
      }
    ];
    const exampleParallaxDatas = [
      {
        start: 700,
        duration: 100,
        properties: [
          {
            startValue: 100,
            endValue: 30,
            property: "translateY"
          },
          {
            startValue: 1.5,
            endValue: -20,
            property: "translateX"
          },
          {
            startValue: 0,
            endValue: 20,
            property: "translate"
          },
          {
            startValue: 0,
            endValue: 5,
            property: "translateY"
          }
        ]
      }
    ];
    return (
      <section className="app_featured_area" id="features">
        <div className="container">
          <div className="row flex-row-reverse app_feature_info">
            <div className="col-lg-6">
              <div className="app_fetured_item">
                <Plx className="app_item item_one" parallaxData={exampleParallaxData}>
                  <i className="ti-face-smile f_size_40 w_color"></i>
                  <h6 className="f_p f_400 f_size_12 w_color l_height45">100% SAFE DELIVERY</h6>
                </Plx>
                <Plx className="app_item item_two" parallaxData={exampleParallaxDatas}>
                  <i className="ti-receipt f_size_40 w_color"></i>
                  <h6 className="f_p f_400 f_size_12 w_color l_height45">24x7 DISPATCH SUPPORT </h6>
                </Plx>
                <div className="app_item item_three" data-parallax='{"x": 50, "y": 10}'>
                  <i className="ti-face-smile f_size_40 w_color"></i>
                  <h6 className="f_p f_400 f_size_12 w_color l_height45">100% GUARNTEED</h6>
                </div>
                <div className="app_item item_four" data-parallax='{"x": -20, "y": 50}'>
                  <i className="ti-tablet f_size_40 w_color"></i>
                  <h6 className="f_p f_400 f_size_12 w_color l_height45">YOUR CARGO OUR CONCERN </h6>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <Reveal bottom cascade>
                <div className="app_featured_content">
                  <h2 className="f_p f_size_30 f_700 t_color3 l_height45 pr_70 mb-30">About Us</h2>
                  <p className="f_400">Bay Leauge Formed  in 2010, largest privately-owned truck transportation providers. Bay Leauge specializes on the timely, protected distribution of goods across the world , We have 20+ employees and 30+ trucks  and 10 Dryvans. Its head office is based in 522 S Fair Oaks Ave # 3. Sunnyvale, CA 94086 USA. <br /> Our group expert’s team has vast experience of transportation the management experience. We work for you, at no extra expense. We sweat the subtleties with the goal that you don't. All you pay for is the expense of delivery. <br /> We provide quick and safe transportation services when you want restrictive utilization of a dry trailer, dope or a level deck to deliver your items, our transporters' fleets are at your disposal. Take advantage of our pre-negotiated rates.</p>
                </div>

              </Reveal>

            </div>
          </div>
        </div>
      </section>
    )
  }
}
export default AppFeaturesTwo;
import React from 'react';
import Sectitle from '../Title/Sectitle';
import HRServiceItems from './HostingServiceitem';

const HostingService =()=> {
    return(
        <section className="hosting_service_area mb-30" id="Service" >
            <div className="container">
                <Sectitle sClass="hosting_title text-center" Title="Service" TitleP="For more then 30 years, Transport has been a reliable partner in the field of logistics and cargo forwarding.!"/>
                <div className="row">
                    <HRServiceItems hostingicon="icon1.png" hostingserviceTitle="100% SAFE DELIVERY" hostingservicedescription="Our capacities give us the freedom of executing project of various sizes. From small personal projects to building mega build of grand scale."/>
                    <HRServiceItems hostingicon="icon2.png" hostingserviceTitle="WORLD WIDE TRANSPORT" hostingservicedescription="In accordance with your needs we organise domestic and international deliveries of collective, complete and partial shipments."/>
                    <HRServiceItems hostingicon="icon3.png" hostingserviceTitle="STORING YOUR CARGO" hostingservicedescription="Our capacities give us the freedom to take care of your cargo from storage to delivery. From small cargo to cargo of grand scale."/>
                    <HRServiceItems hostingicon="icon4.png" hostingserviceTitle="100% GUARNTEED " hostingservicedescription="Safe and on time delivery of your goods is our primary concern. with top notch staff highly modernised vehicles we are confident we can do it."/>
                    <HRServiceItems hostingicon="icon5.png" hostingserviceTitle="SUPPORT FOR YOUR VEHICLES" hostingservicedescription="Having problems with your Truck, Van or any kind of transportation vehicles. We cover everything from service to registration."/>
                    <HRServiceItems hostingicon="icon6.png" hostingserviceTitle="YOUR CARGO OUR CONCERN " hostingservicedescription="We offer a diverse range of transportation services from project cargo to international transportation and domestic retail distribution and delivery."/>
                </div>
            </div>
        </section>
    )
}
export default HostingService;
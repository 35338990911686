import React from "react";

const CoreFeatures = () => {
  return (
    <section className="chat_core_features_area sec_pad" id="service">
      <div className="container">
        <div className="hosting_title chat_title text-center">
          <h2 className="wow fadeInUp" data-wow-delay="0.2s">
            Services

          </h2>
          <p> <span> For more than 10 year of experience in drayage business with vast experienced team..!</span> </p>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp">
              <div className="round">
                <div className="round_circle"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/pluse.png")}
                  alt=""
                />
                <img src={require("../../img/home-chat/chat.png")} alt="" />
              </div>
              <a href=".#">
                <h4>100% SAFE DELIVERY</h4>
              </a>
              <p>
                Our capacities give us the freedom of executing project of various sizes. From small personal projects to building mega build of grand scale.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp" data-wow-delay="0.2s">
              <div className="round">
                <div className="round_circle two"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/triangle.png")}
                  alt=""
                />
                <img src={require('../../img/home-chat/mobile.png')} alt="" />
              </div>
              <a href=".#">
                <h4>100% GUARNTEED </h4>
              </a>
              <p>
                Safe and on time delivery of your goods is our primary concern. with top notch staff highly modernised vehicles we are confident we can do it.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="chat_features_item wow fadeInUp" data-wow-delay="0.4s">
              <div className="round">
                <div className="round_circle three"></div>
                <img
                  className="top_img p_absoulte"
                  src={require("../../img/home-chat/box.png")}
                  alt=""
                />
                <img src={require('../../img/home-chat/book.png')} alt="" />
              </div>
              <a href=".#">
                <h4>YOUR CARGO OUR CONCERN </h4>
              </a>
              <p>
                We offer a diverse range of transportation services from project cargo to international transportation and domestic retail distribution and delivery.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default CoreFeatures;

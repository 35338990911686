import React from 'react';
import OnepageMenu from '../components/OnepageMenu';
import ReactPlayer from "react-player";
import AppFeaturesTwo from '../components/Features/AppFeaturesTwo';
import CoreFeatures from "../components/Features/CoreFeatures";
import Contacts from '../components/Contacts';
import Subscribe from '../components/Subscribe';
import HostingMap from '../components/HostingMap';
import Footer from '../components/Footer/Footer';
import FooterData from '../components/Footer/FooterData';

const Landing = () => {
    return (
        <div className="body_wrapper">
            <OnepageMenu slogo="sticky_logo" mClass="menu_four" nClass="w_menu" hbtnClass="btn_get_radious menu_custfive" />
            <div className="main-video-wrapper" id="background-video">
                <ReactPlayer url={require("../img/bannervideo.mp4")}
                    autoPlay
                    playing={true}
                    muted={true}
                    loop={true}
                    controls={false}
                    width="100%"
                    height="100%"
                />
                <div className="heading-bay" id="Homee">
                    <h1 > Welcome to Bay League </h1>
                    <p> Your best Drayage Partner </p>
                </div>
            </div>
            <AppFeaturesTwo />
            <CoreFeatures />
            <Subscribe FooterData={FooterData} />
            <HostingMap />
            <Contacts />
            <Footer FooterData={FooterData} />
        </div>
    )
}
export default Landing;